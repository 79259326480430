import {Injectable} from "@angular/core";
import {AuthenticationService} from "./authentication.service";
import {ApiService} from "./api.service";
import {Observable, Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";

declare let swal: any;

@Injectable()
export class TeamService {
    constructor(
        private api: ApiService,
        private auth: AuthenticationService,
        private http: HttpClient
    ) {}

    findJobs(): Observable<any> {
        return this.api.get("/pros/account/jobs");
    }

    findCares(): Observable<any> {
        return this.api.get("/pros/account/cares");
    }

    updateCare(care): Observable<any> {
        return this.api.put("/pros/account/cares/" + care.id, care);
    }

    updateOffice(office): Observable<any> {
        return this.api.put("/pros/account/team/office", office);
    }

    GetQrCode(): Observable<any> {
        return this.http.get(environment.origin + "/pros/account/qrCode", {
            responseType: "blob"
        });
    }

    getTeamByUrl(office): Observable<any> {
        return this.api.get(
            "/pros/account/team/jobId/" +
                office.job_id +
                "/city/" +
                office.office_city +
                "/zipcode/" +
                office.office_zipcode +
                "/name/" +
                office.name
        );
    }

    joinOtherTeam(team_id, pro_id): Observable<any> {
        return this.api.post("/pros/account/team/join/" + team_id, {
            id: pro_id
        });
    }

    getTour(): Observable<any> {
        return this.api.get("/pros/account/team/office/tour");
    }

    updateTour(tour, area): Observable<any> {
        return this.api.put("/pros/account/team/tour", {
            tour: tour,
            area: area
        });
    }

    getVisitHours(): Observable<any> {
        return this.api.get("/pros/account/visitHours");
    }

    updateFrames(frames): Observable<any> {
        return this.api.put("/pros/account/visitHours", {frames: frames});
    }

    getColleagues(): Observable<any> {
        return this.api.get("/pros/account/team/colleagues");
    }

    endConfig(referrerId): Observable<any> {
        return this.api
            .put("/pros/account/end-config", {sendSms: true, referrerId})
            .map(response => {
                return response;
            });
    }

    findSpecialties(): Observable<any> {
        return this.api.get("/pros/account/specialties");
    }

    updateSpecialty(specialty): Observable<any> {
        return this.api.put(
            "/pros/account/specialties/" + specialty.id,
            specialty
        );
    }

    updateVisibilityAndSendConfirmationEmail(data): Observable<any> {
        return this.api.put(`/pros/account/team/visibility`, data);
    }

    sendoneSignalId(oneSignalId): Observable<any> {
        return this.api.put("/pros/account/webToken/" + oneSignalId, null);
    }

    createWebsite(websiteInfos): Observable<any> {
        return this.api.post("/pros/account/team/website", websiteInfos);
    }

  showModal(title, description) {
    const msg = {
      title: title,
      html: `
            <div>
               <p>${description}</p>
            </div>
        `
    };
    swal(msg);
  }







}
